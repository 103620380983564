export default function carousels_init(){
    let containers = document.querySelectorAll('.ff-swiper');
    if( !containers.length ) return;

    load_swiper(()=>{
        containers.forEach(container=>{
            setTimeout(()=>swiper_init(container), 0);
        });
    });
}

function swiper_init(container){

    if( typeof container.swiper !== 'undefined' ) return;

    let settings = container.dataset.swiper_settings;

    settings = typeof settings === 'undefined' ? {} : JSON.parse(settings); 
    
    pagination_support(container, settings);
    navigation_support(container, settings);
    scrollbar_support(container, settings);

    settings = default_settings(settings);
    new Swiper(container, settings);
}

function pagination_support(container, settings){

    let pagination = container.querySelector('.swiper-pagination');
    if( !pagination ) return;

    if( typeof settings.pagination === 'undefined' ) {
        settings.pagination = {
            clickable: true,
        };
    }

    settings.pagination.el = pagination;
}

function navigation_support(container, settings){

    if( typeof settings.navigation === 'undefined' ) {
        settings.navigation = {};
    }

    let nav_prev = container.querySelector('.swiper-button-prev');
    if( nav_prev ) {
        settings.navigation.prevEl = nav_prev;
    }

    let nav_next = container.querySelector('.swiper-button-next');
    if( nav_next ) {
        settings.navigation.nextEl = nav_next;
    }

}

function scrollbar_support(container, settings) {

    let scrollbar = container.querySelector('.swiper-scrollbar');
    if( !scrollbar ) return;

    if( typeof settings.scrollbar === 'undefined' ) {
        settings.scrollbar = {
            draggable: true,
        };
    }
    
    settings.scrollbar.el = scrollbar;
}

function default_settings(settings){
    settings.speed = settings.speed ?? 700;

    if( typeof settings.autoplay !== 'undefined' ) {
        settings.autoplay.disableOnInteraction = settings.autoplay.disableOnInteraction ?? false;
    }
    
    return settings;
}