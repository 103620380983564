const supported_types = [
    '.gfield--type-text',
    '.gfield--type-email',
    '.gfield--type-phone',
    '.gfield--type-textarea',
];

export default function form_style_2_init(container){

    if( !container.closest('.form_style_2') ) return;
    // console.log('form_style_2_init', container)

    container.querySelectorAll(supported_types.join(', ')).forEach(field_container=>{
        const input = field_container.querySelector('input,textarea')
        if( !input ) return;

        // on load, activate if have value
        if( input.value ) {
            active();
        }

        input.addEventListener('focus', active);
        input.addEventListener('blur', inactive);

        function active(){
            field_container.classList.add('field_active');
        }
        function inactive(){
            if( !input.value ) {
                field_container.classList.remove('field_active');
            }
        }
    })

}