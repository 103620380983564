import './cart_popup.scss';
import { ajax_fetch } from 'src/api/ajax';
import { create_element } from 'js/utils';

const open_animation_duration = 700;
const remove_animation_duration = 400;

let container;
let content;
let close_button;
let count_html;
let items_html;
let total_html;
let free_shipping;


let refresh = false;
let is_open = false;

init_html();


let cart_contents_ready = false;
document.querySelector('.woo_header_buttons .cart_count').addEventListener('click',e=>{
    e.preventDefault();

    if(document.body.classList.contains('woocommerce-checkout')){ 
        return false;
    } 

    if(document.body.classList.contains('woocommerce-cart')){ 
        return false;
    } 

    if( !cart_contents_ready ) {
        document.body.dispatchEvent(new Event('cart_item_add'));
    } else {
        open();
    }
});


document.querySelectorAll('.add_to_cart_ajax').forEach(el => {
    el.addEventListener('click',e=>{
        e.preventDefault();
        if( !cart_contents_ready ) {
            document.body.dispatchEvent(new Event('cart_item_add'));
        } else {
            open();
        }
    });
});
    

document.body.addEventListener('cart_item_add', ()=>{

    if( refresh ) {
        loading([content]);
    }

    ajax_fetch('cart_popup_item_add', (res)=>{

        if( refresh ) {
            refresh_html(res); 
            loading_complete([content]);
        }
        else {
            initial_html(res);
        }
        
        open();
        cart_contents_ready = true;
        
    }, { refresh });


});

function initial_html(res){
    content.innerHTML = res.html;
    
    init_items();
    init_coupon();

    refresh = true;

    count_html = content.querySelector('.items_count');
    items_html = content.querySelector('.cart_popup_items');
    total_html = content.querySelector('.cart_popup_total .details');
    free_shipping = content.querySelector('.free_shipping_indicator .free_shipping');
}

function init_items(){
    content.querySelectorAll('.item').forEach(item=>{
        init_remove(item);
        init_qty(item);
    })
}

function refresh_html(res){
    if( typeof res.count_html !== 'undefined' ) {
        count_html.innerHTML = res.count_html;
    }

    if( typeof res.total_html !== 'undefined' ) {
        total_html.innerHTML = res.total_html;
    }

    if( typeof res.items_html !== 'undefined' ) {
        items_html.innerHTML = res.items_html;
        init_items();
    } 
    if(free_shipping){
        adjust_indicator();
    }
    
}

function init_html(){

    container = create_element({class_name: 'cart_popup', render: document.body})
    container.id = 'cart_popup';
    container.style.transition = `transform ${open_animation_duration}ms ease`;
    container.style.display = 'none';
    container.style.transform = 'translateX(100%)';

    content = create_element({class_name: 'cart_popup_content', render: container})

    close_button = create_element({class_name: 'close', render: container})
    close_button.addEventListener('click', close);
    
}

function close(){
    is_open = false;
    
    container.style.transform = 'translateX(100%)';

    setTimeout(()=>{
        container.style.display = 'none';
    }, open_animation_duration);
    
    document.body.removeEventListener('click', outside_click_close)
}

function open(){
    if( is_open ) return;

    is_open = true;
    container.style.display = '';

    setTimeout(()=>{
        container.style.transform = 'translateX(0)';
    }, 10);

    setTimeout(()=>{
        document.body.addEventListener('click', outside_click_close)
    }, 100);
 

    if(free_shipping){
        adjust_indicator();
    }
}

function loading(els){
    els.forEach(el=>{
        if( !el ) return;  
        el.classList.add('loading');
    });
}

function loading_complete(els){
    els.forEach(el=>{
        if( !el ) return;
        el.classList.remove('loading');
    });
}

function outside_click_close(e){
    if( e.target == container ) return;
    if( e.target.closest('.cart_popup') ) return;
    if( e.target.classList.contains('add_to_cart_ajax') ) return; 
    close();
}

function init_remove(item){
    const remove_btn = item.querySelector('.remove'); 
    remove_btn.addEventListener('click', ()=>{
        item.classList.add('removing');

        loading([count_html, total_html]);

        ajax_fetch('cart_popup_item_remove', (res)=>{
            remove_item(item);
            refresh_html(res); 
            loading_complete([count_html, total_html]);
        }, {item_key: item.dataset.key});   
        
    });
}

function remove_item(item){
    item.style.transform = 'scale(.9)';
    item.style.opacity = 0;
    
    setTimeout(()=>{
        item.remove();
        if(free_shipping){
            adjust_indicator();
        }
    }, remove_animation_duration);  
}

function init_qty(item){

    let timeout = null;

    const minus = item.querySelector('.minus');
    const plus = item.querySelector('.plus');
    const qty = item.querySelector('.qty_input');
    const price = qty.dataset.price;
    const item_total = item.querySelector('.amount .value');

    let qty_min = parseInt(qty.min||1);
    let qty_max = qty.max || 9999;
    if( qty_max == -1 ) qty_max = 9999;

    qty.addEventListener('change', update_item_total)

    minus.addEventListener('click', ()=>{
        qty.value--;
        if( qty.value < qty_min ) {
            qty.value = qty_min;
        }
        clearTimeout(timeout);
        timeout = setTimeout(update_item_total, 1000);
    });

    plus.addEventListener('click', ()=>{
        qty.value++;
        if( qty.value > qty_max ) {
            qty.value = qty_max;
        }
        clearTimeout(timeout);
        timeout = setTimeout(update_item_total, 1000);
    });
    
    function update_item_total(){
        let updated_amount = parseFloat(price) * qty.value;
        updated_amount = updated_amount.toLocaleString('us', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        item_total.textContent = updated_amount;

        loading([total_html]);
        ajax_fetch('cart_popup_item_quantity_update', (res)=>{
            refresh_html(res);
            loading_complete([total_html]);
        }, {item_key: item.dataset.key, quantity: qty.value });
    }
}

function init_coupon(){
    const form = content.querySelector('.cart_popup_coupon form');
    if( !form ) return;

    const input = form.querySelector('.coupon_code_input');
    const message = form.querySelector('.coupon_message');

    form.addEventListener('submit', (e)=>{
        e.preventDefault();
        if( !input.value ) return;
        loading([items_html, total_html]);
        ajax_fetch('cart_popup_apply_coupon', (res)=>{
            message.innerHTML = res.message;
            refresh_html(res);
            loading_complete([items_html, total_html]);
        }, {coupon_code: input.value });
    });
}

function adjust_indicator(){  

    let total_amount = 0 ;
    let price;
    let target_amount = parseInt(free_shipping.dataset.free_shipping); 

    content.querySelectorAll('.cart_popup_items .amount .value').forEach(item => {
        price = parseFloat(item.textContent.replace(/"|\,|\./g, '')/100); 
        total_amount = total_amount + price;  
    });  

    let difference = target_amount - total_amount ;
    let new_total = parseInt((total_amount/target_amount) * 100); 
    
    difference = (difference < 0)?  0 : difference; 
    new_total =(new_total > 100 )? 100 : new_total; 
    
    free_shipping.querySelector('.bar').style = `--free-indicator:${new_total}%`;
    free_shipping.querySelector('.adjust_label').textContent = `${new_total}%`;
    free_shipping.querySelector('.difference').textContent = `$${difference}`;
}