const is_mobile = window.innerWidth < 768 ;

$ = jQuery; 


init();
function init(){ 
    
    if(!is_mobile) return;  
    mobile_accordion();
}

function mobile_accordion(){  

    var delay = 100; 
    setTimeout(function() {
        $('.e-n-accordion-item').removeAttr('open'); 
    }, delay); 
}
