import { on_ready } from 'src/js/utils';
import ScrollToTop from 'src/classes/Scroll_To_Top';
  
on_ready(()=>{
    
    let button = document.querySelectorAll('.scroll-to-top');
     
    button.forEach(item => {
        new ScrollToTop(item);
    });
});
 