let header;

let direction;
let delta_y = 0;
let activation_point;
let is_sticky = false;
let spacer_inititialized = false;
let state = 'initial';

// let row_1 = header.querySelector('.header_right_row_1');

export default function header_init(){

    header = document.querySelector('.elementor-location-header');
    if( !header ) return;
    
    activation_point = header.offsetHeight * 3;

    setTimeout(()=>{
        document.addEventListener('scroll', on_scroll);
    }, 500);
}

function on_activate(){
    // row_1.wrapper.style.height = 0;
}

function on_deactivate(){
    // row_1.wrapper.style.height = row_1.offsetHeight + 'px';
}

function on_scroll(){
    
    direction = window.scrollY > delta_y ? 'down' : 'up';
    delta_y = window.scrollY;
    
    if( direction == 'down' ) {

        // scroll down

        if( !is_sticky && window.scrollY > activation_point ) {
            setTimeout(()=>sticky_activate(), 10);
        }
        
        hide();
    }
    else {

        // scroll up
        
        if( is_sticky && window.scrollY <= activation_point ) {
            on_deactivate();
        }

        if( window.scrollY == 0 ) {
            setTimeout(()=>sticky_deactivate(), 10);
        }
        
        show();
    }
}

function spacer_init(){
    if( spacer_inititialized ) return;
    spacer_inititialized = true;

    // console.log('spacer_init');

    if( ['absolute', 'fixed'].indexOf(window.getComputedStyle(header).position) !== -1 ) {
        // no need to add spacer for absolute or fixed
        return;
    };

    let spacer = document.createElement('div');
    spacer.classList.add('site_header_spacer');
    spacer.style.minHeight = header.offsetHeight + 'px';
    header.after(spacer);
    spacer.append(header);
}

function sticky_activate(){

    // console.log('sticky_activate');

    on_activate();

    spacer_init();

    header.style.transform = 'translateY(-100%)';
    header.style.display = 'none';
    // header.classList.add('sticky_active');
    document.body.classList.add('header_sticky_active');

    setTimeout(()=>{
        header.style.display = '';
    }, 50);

    is_sticky = true;
}

function sticky_deactivate(){
    header.style.position = '';
    // header.classList.remove('sticky_active');
    header.style.transform = '';
    document.body.classList.remove('header_sticky_active');
    is_sticky = false;
}

function show(){
    if( state == 'show' ) return;
    if( !is_sticky ) return;
    state = 'show';
    header.style.transform = 'translateY(0)';
    header.style.position = 'fixed';
    header.style.zIndex = 100;
}

function hide(){
    if( state == 'hide' ) return;
    if( !is_sticky ) return;
    state = 'hide';
    header.style.transform = 'translateY(-190%)';
}

function wrap(element, class_name){
    if( !element ) return;
    let wrapper = document.createElement('div');
    wrapper.classList.add(class_name);
    element.after(wrapper);
    wrapper.append(element);
    return wrapper;
}